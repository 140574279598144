import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import API_ENDPOINTS from '/src/app/apiEndpoints'
import { callAPI } from '/src/utils/apiUtils'
import { requirementsSelector, setRequirementsData } from './RequirementsPane.module'
import RequirementsPaneView from './RequirementsPane.view'
import './RequirementsPane.scss'


const RequirementsPane = memo(({ 
  hostname,
  showExactOption, 
  disabled, 
  hiddenRequirements, 
  exposeCustomRA,
  partnerVendors,
  vendorsFromResults,
  jsonName,
  setResults,
  user,
  handlerWithResultsScroll}) => {
  const { vendors, feature_set, deployment_type } = useSelector(requirementsSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!vendors) {
      let payload = {hostname: encodeURIComponent(hostname), email: _.get(user, 'email')}
      if (jsonName) {
        payload["jsonName"] = jsonName
      }
      callAPI({
        endpoint: API_ENDPOINTS.GET_REQUIREMENTS_DATA,
        payload,
        callback: (data) => {
          let vendors = _.get(data, 'vendors')
          const performanceProfiles = _.get(data, 'performance_profiles')
          if (partnerVendors) {
            vendors = _.filter(vendors, v => _.includes(partnerVendors, v[0]))
          }
          dispatch(setRequirementsData({vendors, performanceProfiles}))
        }
      })
    }
  }, [user])

  const downloadAll = () => {
    callAPI({
      endpoint: API_ENDPOINTS.POST_RAS, 
      payload: { 
        hostname, 
        future_versions: feature_set === 'Future',
        jsonName,
        deployment_type
      }, 
      callback: (data) => {
        if (data["error"]) {
          dispatch(setResults(data))
        } else {
          const csvContent = `data:text/csv;charset=utf-8,${_.get(data, "data")}`
          const encodedUri = _.replace(encodeURI(csvContent), new RegExp("#","g"),"%23")

          // Downloading
          let link = document.createElement("a")
          link.setAttribute("href", encodedUri)
          link.setAttribute("download", `${new Date()}.csv`)
          document.body.appendChild(link); // Required for FF
          link.click();
        }
      }
    })
  }
  
  const props = {
    handlerWithResultsScroll,
    disabled,
    exposeCustomRA,
    hiddenRequirements,
    showExactOption,
    isPartner: !_.isUndefined(partnerVendors),
    vendorsFromResults,
    user,
    downloadAll
  }

  return <RequirementsPaneView {...props} />
})

export default RequirementsPane