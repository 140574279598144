import React, { memo } from 'react'
import _ from 'lodash'
import { Button } from '/src/storybook/components/Button'
import { Radio } from '/src/storybook/components/Radio'
import { Checkbox } from '/src/storybook/components/Checkbox'
import { useDispatch, useSelector } from 'react-redux'
import { setRequirement, setSelectedVendors, requirementsSelector } from '../RequirementsPane.module'
import './OutputControl.scss'


const OutputControl = memo(({ vendorsFromResults, handlerWithResultsScroll }) => {
  const { sorting, selectedVendors } = useSelector(requirementsSelector)
  const dispatch = useDispatch()
  
  return (
    <div className="requirement-box output-control" key={_.join(selectedVendors, "-")}>
      <div className="requirement-box-title">Output control</div>
      <div className="requirement-row">
        <b className="for-radio">Optimization</b>
        <Radio name="sorting" selected={sorting} items={[
          {label: "Cost", value: "by_cost"},
          {label: "Space", value: "by_space"},
        ]} onSelected={handlerWithResultsScroll(newSorting =>
          dispatch(setRequirement({sorting: newSorting}))
        )}/>
      </div>
      {_.size(vendorsFromResults) > 1 &&
        <>
          <div className="vendors-title">Vendors</div>
          {
            _.map(vendorsFromResults, name => (
              <div className="vendor-container" key={name}>
                <Checkbox
                  name={name}
                  checked={_.includes(selectedVendors, name)}
                  onChecked={(value) => {
                    const newVendors = value ? [...selectedVendors, name] : _.filter(selectedVendors, (v) => v != name)
                    dispatch(setSelectedVendors(newVendors))
                  }}
                />
                <Button
                  secondary
                  label="Check only this"
                  onClick={handlerWithResultsScroll(() => dispatch(setSelectedVendors([name,])))}
                />
              </div>
            ))
          }
        </>
      }
    </div>
  )
})

export default OutputControl