import React, { memo } from 'react'
import { Tabs } from '/src/storybook/components/Tabs'
import { ByRequirementsTab } from './ByRequirementsTab'
import { BySizingTab } from './BySizingTab'
import { useDispatch, useSelector } from 'react-redux'
import { setRequirement, requirementsSelector } from '../RequirementsPane.module'
import './Capacity.scss'
import _ from "lodash";


const Capacity = memo(({ exposeCustomRA, hotAndWarm, setHotAndWarm, hiddenRequirements, handlerWithResultsScroll, isPartner}) => {
  const { activeTab } = useSelector(requirementsSelector)
  const dispatch = useDispatch()
  const isChannel = _.startsWith(window.location.hostname, 'channel.')
  const showTabs = !isChannel && !isPartner

  return (
    <div className="system-capacity">
      <div className="requirement-box-title">System capacity</div>
      <div>
        {!showTabs && (
          <ByRequirementsTab
            hotAndWarm={hotAndWarm}
            setHotAndWarm={setHotAndWarm}
            handlerWithResultsScroll={handlerWithResultsScroll}
          />
        )}
        {showTabs && (
          <Tabs tabs = {[
              {name: 'BY REQUIREMENT', content: <ByRequirementsTab
                hotAndWarm={hotAndWarm}
                setHotAndWarm={setHotAndWarm}
                handlerWithResultsScroll={handlerWithResultsScroll}
              />},
              {name: 'BY SIZING', content: <BySizingTab
                exposeCustomRA={exposeCustomRA}
                hotAndWarm={hotAndWarm}
                setHotAndWarm={setHotAndWarm}
                hiddenRequirements={hiddenRequirements}
                handlerWithResultsScroll={handlerWithResultsScroll}
              />}
            ]}
            onSelected={handlerWithResultsScroll((tabName) => dispatch(setRequirement({activeTab: tabName})))}
            selected={activeTab}
          />
        )}
      </div>
    </div>
  )
})

export default Capacity
